<template>
  <div class="report">
    <div class="actionBar"></div>

    <a-tabs v-model="listParams.report_type" @change="callback">
      <a-tab-pane key="1" tab="文章"> </a-tab-pane>
      <a-tab-pane key="2" tab="评论"> </a-tab-pane>
      <a-tab-pane key="3" tab="视频"> </a-tab-pane>
      <a-tab-pane key="4" tab="病例"> </a-tab-pane>
    </a-tabs>
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <a-form
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        :from="addFromData"
      >
        <a-form-item label="举报人">
          {{ addFromData.reporter_name }}
        </a-form-item>
        <a-form-item label="内容">
          <a-textarea
            v-model="addFromData.reply"
            placeholder="请输入回复内容"
            :auto-size="{ minRows: 6, maxRows: 8 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="action" slot-scope="text, record">
        <span
          v-if="record.status == 0 && handLists.right?handLists.right.indexOf('reply') != -1:false"
        >
          <FontIcon
            @click.native="editMenu(record)"
            title="回复"
            type="icon-pinglun"
            size="22"
          ></FontIcon>
          <a-divider type="vertical" />
        </span>

        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          v-if="handLists.right?handLists.right.indexOf('del') != -1:false"
        >
          <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import { reportIndex, reportDataReply, reportDelete } from "@/apis/index";

import moment from "moment";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    width: 120,
    align: "center",
  },
  {
    title: "举报人",
    dataIndex: "reporter_name",
    scopedSlots: { customRender: "reporter_name" },
  },
  {
    title: "标题",
    dataIndex: "title",
    scopedSlots: { customRender: "title" },
    customRender: (text, record) => {
      if (record.articles) {
        return record.articles.title;
      } else if (record.comments) {
        return record.comments.content;
      } else if (record.videos) {
        return record.videos.title;
      } else if (record.cases) {
        return record.cases.title;
      }
    },
  },
  {
    title: "发布者",
    dataIndex: "users",
    scopedSlots: { customRender: "users" },
    customRender: (text, record) => {
      if (record.articles) {
        return record.articles.users.user_realname
          ? record.articles.users.user_realname
          : record.articles.users.user_nickname;
      } else if (record.comments) {
        return record.comments.users.user_realname
          ? record.comments.users.user_realname
          : record.comments.users.user_nickname;
      } else if (record.videos) {
        return record.videos.users.user_realname
          ? record.videos.users.user_realname
          : record.videos.users.user_nickname;
      } else if (record.cases) {
        return record.cases.users.user_realname
          ? record.cases.users.user_realname
          : record.cases.users.user_nickname;
      }
    },
  },
  {
    title: "举报原因",
    dataIndex: "reason",
    scopedSlots: { customRender: "reason" },
  },
  {
    title: "举报时间",
    dataIndex: "report_time",
    scopedSlots: { customRender: "report_time" },
    customRender: (text, record) => {
      return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    customRender: (text, record) => {
      if (text == 1) {
        return "已回复";
      } else if (text == 0) {
        return "未回复";
      }
    },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},
      listParams: {
        page: 1,
        limit: 1,
        report_type: "1",
      },
      count: 0,

      handLists: this.$store.state.menuHandleList,
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists = val
    },
  },
  created() {
    this.getReportIndex();
  },
  methods: {
    callback(key) {
      this.listParams.page = 1;
      this.getReportIndex();
    },
    getReportIndex() {
      reportIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getReportIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    handleOk(e) {
      this.confirmLoading = true;
      reportDataReply(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.getReportIndex();
          this.visible = false;
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    editMenu(record) {
      this.modalTitle = "回复";
      this.addFromData = {
        id: record.id,
        reply: record.reply,
        reporter_name: record.reporter_name,
      };
      this.visible = true;
    },
    confirm(rec) {
      reportDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getReportIndex();
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getReportIndex();
    },
  },
};
</script>
<style lang="less" scoped>
.report {
  .actionBar {
    padding: 30px 0;
  }
}
</style>
